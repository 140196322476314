<template>
    <div class="main">
        <div class="bg">
            <img src="../../assets/images/common/yingyezhizhao.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.bg{
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/common/mosha.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
}
.bg img{
    width: 80%;
    display: block;
    margin: 0 auto;
}
</style>